import axios from 'axios';
import store from '../vuex/store';

const { VUE_APP_BASE_URL, VUE_APP_API_VER } = process.env;

const axiosInstance = axios.create({
  timeout: 100000,
  withCredentials: true,
  baseURL: `${VUE_APP_BASE_URL}/${VUE_APP_API_VER}`
});

axiosInstance.defaults.headers.common.Accept = 'application/json';

axiosInstance.interceptors.request.use(
  response => response,
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      return Promise.reject(error);
    } else {
      const notify = {
        message: 'System error occurred, please contact your administrator'
      };
      store.dispatch('notify/show', notify);
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
