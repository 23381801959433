/* eslint-disable no-shadow */
// import axios from 'axios';
import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  constituency: {},
  constituencyLoading: false,

  constituencies: {},
  constituenciesLoading: false,

  createConstituency: {},
  createConstituencyLoading: false,

  updateConstituency: {},
  updateConstituencyLoading: false,

  deleteConstituency: {},
  deleteConstituencyLoading: false
};

// mutations
const mutations = {
  SET_CONSTITUENCY: (state, constituency) => {
    state.constituency = constituency;
  },
  SET_CONSTITUENCY_LOADING: (state, constituencyLoading) => {
    state.constituencyLoading = constituencyLoading;
  },

  SET_CONSTITUENCIES: (state, constituencies) => {
    state.constituencies = constituencies;
  },
  SET_CONSTITUENCIES_LOADING: (state, constituenciesLoading) => {
    state.constituenciesLoading = constituenciesLoading;
  },

  SET_CREATE_CONSTITUENCY: (state, createConstituency) => {
    state.createConstituency = createConstituency;
  },
  SET_CREATE_CONSTITUENCY_LOADING: (state, createConstituencyLoading) => {
    state.createConstituencyLoading = createConstituencyLoading;
  },

  SET_UPDATE_CONSTITUENCY: (state, updateConstituency) => {
    state.updateConstituency = updateConstituency;
  },
  SET_UPDATE_CONSTITUENCY_LOADING: (state, updateConstituencyLoading) => {
    state.updateConstituencyLoading = updateConstituencyLoading;
  },

  SET_DELETE_CONSTITUENCY: (state, deleteConstituency) => {
    state.constituencies.data = state.constituencies.data.filter(
      item => item._id !== deleteConstituency.id
    );
  },
  SET_DELETE_CONSTITUENCY_LOADING: (state, deleteConstituencyLoading) => {
    state.deleteConstituencyLoading = deleteConstituencyLoading;
  }
};

// actions
const actions = {
  onFetchConstituencies: async ({ commit, dispatch }, filterOptions) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CONSTITUENCIES_LOADING', true);
    try {
      const res = await apiClient.get('/constituencies', {
        params: { limit: 2000, ...filterOptions },
        paramsSerializer: qs.stringify
      });
      commit('SET_CONSTITUENCIES', res.data);
      commit('SET_CONSTITUENCIES_LOADING', false);
      if (res.data?.data.length === 0) {
        dispatch(
          'notify/show',
          { type: 'info', message: 'No constituencies result found.' },
          { root: true }
        );
      }
    } catch (error) {
      commit('SET_CONSTITUENCIES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchConstituency: async ({ commit, dispatch }, constituencyId) => {
    commit('SET_CONSTITUENCY_LOADING', true);
    commit('SET_CONSTITUENCY', {});
    try {
      const res = await apiClient.get(`/constituencies/${constituencyId}`);
      commit('SET_CONSTITUENCY', res.data?.constituency);
      setTimeout(() => {
        commit('SET_CONSTITUENCY_LOADING', false);
      }, 1000);
    } catch (error) {
      commit('SET_CONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateConstituency: async ({ commit, dispatch }, constituencyData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_CONSTITUENCY_LOADING', true);
    commit('SET_CREATE_CONSTITUENCY', {});
    try {
      const res = await apiClient.post(`/constituencies`, constituencyData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_CONSTITUENCY', res.data);
        commit('SET_CREATE_CONSTITUENCY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_CONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateConstituency: async ({ commit, dispatch }, constituencyData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_CONSTITUENCY_LOADING', true);
    commit('SET_UPDATE_CONSTITUENCY', {});
    try {
      const res = await apiClient.put(
        `/constituencies/${constituencyData._id}`,
        constituencyData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_CONSTITUENCY', res.data);
        commit('SET_UPDATE_CONSTITUENCY_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_CONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteConstituency: async ({ commit, dispatch }, constituencyId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(`/constituencies/${constituencyId}`);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingConstituency: state =>
    state.constituencyLoading ||
    state.constituenciesLoading ||
    state.createConstituencyLoading ||
    state.updateConstituencyLoading ||
    state.deleteConstituencyLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
