import qs from 'qs';
import { get, apiClient } from '../../utils';

// initial state
const state = {
  notifications: {},
  notificationsLoading: false,

  createNotification: {},
  createNotificationLoading: false
};

// mutations
const mutations = {
  SET_NOTIFICATIONS: (state, notifications) => {
    state.notifications = notifications;
  },
  SET_NOTIFICATIONS_LOADING: (state, notificationsLoading) => {
    state.notificationsLoading = notificationsLoading;
  },

  SET_CREATE_NOTIFICATION: (state, createNotification) => {
    state.createNotification = createNotification;
  },
  SET_CREATE_NOTIFICATION_LOADING: (state, createNotificationLoading) => {
    state.createNotificationLoading = createNotificationLoading;
  }
};

// actions
const actions = {
  onFetchNotifications: async ({ commit, dispatch }, filtersData) => {
    try {
      commit('SET_NOTIFICATIONS_LOADING', true);
      const res = await apiClient.get('/notifications', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status === 200) {
        commit('SET_NOTIFICATIONS', res.data);
        commit('SET_NOTIFICATIONS_LOADING', false);
      }
    } catch (error) {
      commit('SET_NOTIFICATIONS', {});
      commit('SET_NOTIFICATIONS_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', 'somethingWrong')
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onCreateMessage: async ({ commit, dispatch }, messageData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CREATE_NOTIFICATION', {});
      commit('SET_CREATE_NOTIFICATION_LOADING', true);
      const res = await apiClient.post('/messages', messageData);
      if (res.status === 201) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_NOTIFICATION', res.data);
        commit('SET_CREATE_NOTIFICATION_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_NOTIFICATION', {});
      commit('SET_CREATE_NOTIFICATION_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(error, 'response.data.message', 'somethingWrong')
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingNotification: state =>
    state.notificationsLoading || state.createNotificationLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
