import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  programs: {},
  programsLoading: false,

  programsData: [],

  program: {},
  programLoading: false,

  createProgram: {},
  createProgramLoading: false,

  updateProgram: {},
  updateProgramLoading: false,

  programsByUser: {},
  programsByUserLoading: false
};

// mutations
const mutations = {
  SET_PROGRAMS: (state, programs) => {
    state.programs = programs;
  },
  SET_PROGRAMS_LOADING: (state, programsLoading) => {
    state.programsLoading = programsLoading;
  },

  SET_PROGRAMS_DATA: (state, programsData) => {
    state.programsData = programsData;
  },

  SET_PROGRAM: (state, program) => {
    state.program = program;
  },
  SET_PROGRAM_LOADING: (state, programLoading) => {
    state.programLoading = programLoading;
  },

  SET_CREATE_PROGRAM: (state, createProgram) => {
    if (createProgram?.program) {
      state.programs = {
        ...state.programs,
        data: [createProgram.program, ...state.programs.data]
      };
    }
    state.createProgram = createProgram;
  },
  SET_CREATE_PROGRAM_LOADING: (state, createProgramLoading) => {
    state.createProgramLoading = createProgramLoading;
  },

  SET_UPDATE_PROGRAM: (state, updateProgram) => {
    state.programs.data = state.programs.data.map(item => {
      if (item._id === updateProgram?.program?._id) {
        item = updateProgram.program;
      }
      return item;
    });
    state.updateProgram = updateProgram;
  },
  SET_UPDATE_PROGRAM_LOADING: (state, updateProgramLoading) => {
    state.updateProgramLoading = updateProgramLoading;
  },

  SET_PROGRAMS_BY_USER: (state, programsByUser) => {
    state.programsByUser = programsByUser;
  },
  SET_PROGRAMS_BY_USER_LOADING: (state, programsByUserLoading) => {
    state.programsByUserLoading = programsByUserLoading;
  }
};

// actions
const actions = {
  onFetchPrograms: async ({ commit, dispatch }, filtersData) => {
    commit('SET_PROGRAMS_LOADING', true);
    // commit('SET_PROGRAMS', {});
    try {
      const res = await apiClient.get('/programs', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_PROGRAMS', res.data);
      commit('SET_PROGRAMS_LOADING', false);
    } catch (error) {
      commit('SET_PROGRAMS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchProgram: async ({ commit, dispatch }, programId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_PROGRAM_LOADING', true);
    commit('SET_PROGRAM', {});
    try {
      const res = await apiClient.get(`/programs/${programId}`);
      if (res.status >= 200) {
        commit('SET_PROGRAM', res.data.program);
        setTimeout(() => {
          commit('SET_PROGRAM_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_PROGRAM_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateProgram: async ({ commit, dispatch }, programData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_PROGRAM_LOADING', true);
    commit('SET_CREATE_PROGRAM', {});
    try {
      const res = await apiClient.post(`/programs`, programData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_PROGRAM', res.data);
        commit('SET_CREATE_PROGRAM_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_PROGRAM_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateProgram: async ({ commit, dispatch }, programData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_PROGRAM', {});
    commit('SET_UPDATE_PROGRAM_LOADING', true);
    try {
      const res = await apiClient.put(
        `/programs/${programData._id}`,
        programData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_PROGRAM', res.data);
        commit('SET_UPDATE_PROGRAM_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_PROGRAM_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchProgramsByUser: async ({ commit, dispatch }) => {
    commit('SET_PROGRAMS_BY_USER_LOADING', true);
    commit('SET_PROGRAMS_BY_USER', {});
    try {
      const res = await apiClient.get('/programs/me');
      commit('SET_PROGRAMS_BY_USER', res.data.programs);
      commit('SET_PROGRAMS_BY_USER_LOADING', false);
    } catch (error) {
      commit('SET_PROGRAMS_BY_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  programsLabels: state => state.programs.data.map(item => item.name),
  programsColors: state => state.programs.data.map(item => item.color),
  programsData: state => state.programsData,
  loadingProgram: state =>
    state.programsLoading ||
    state.programLoading ||
    state.createProgramLoading ||
    state.updateProgramLoading ||
    state.programsByUserLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
