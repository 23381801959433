import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  donors: {},
  donorsLoading: false,

  donor: {},
  donorLoading: false,

  createDonor: {},
  createDonorLoading: false,

  updateDonor: {},
  updateDonorLoading: false
};

// mutations
const mutations = {
  SET_DONORS: (state, donors) => {
    state.donors = donors;
  },
  SET_DONORS_LOADING: (state, donorsLoading) => {
    state.donorsLoading = donorsLoading;
  },

  SET_DONOR: (state, donor) => {
    state.donor = donor;
  },
  SET_DONOR_LOADING: (state, donorLoading) => {
    state.donorLoading = donorLoading;
  },

  SET_CREATE_DONOR: (state, createDonor) => {
    if (createDonor?.donor) {
      state.donors = {
        ...state.donors,
        data: [createDonor.donor, ...state.donors.data]
      };
    }
    state.createDonor = createDonor;
  },
  SET_CREATE_DONOR_LOADING: (state, createDonorLoading) => {
    state.createDonorLoading = createDonorLoading;
  },

  SET_UPDATE_DONOR: (state, updateDonor) => {
    state.donors.data = state.donors.data.map(item => {
      if (item._id === updateDonor?.donor?._id) {
        item = updateDonor.donor;
      }
      return item;
    });
    state.updateDonor = updateDonor;
  },
  SET_UPDATE_DONOR_LOADING: (state, updateDonorLoading) => {
    state.updateDonorLoading = updateDonorLoading;
  }
};

// actions
const actions = {
  onFetchDonors: async ({ commit, dispatch }, filtersData) => {
    commit('SET_DONORS_LOADING', true);
    // commit('SET_DONORS', {});
    try {
      const res = await apiClient.get('/donors', {
        params: { sort: 'name', ...filtersData },
        paramsSerializer: qs.stringify
      });
      commit('SET_DONORS', res.data);
      commit('SET_DONORS_LOADING', false);
    } catch (error) {
      commit('SET_DONORS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchDonor: async ({ commit, dispatch }, donorId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DONOR_LOADING', true);
    commit('SET_DONOR', {});
    try {
      const res = await apiClient.get(`/donors/${donorId}`);
      if (res.status >= 200) {
        commit('SET_DONOR', res.data.donor);
        setTimeout(() => {
          commit('SET_DONOR_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_DONOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateDonor: async ({ commit, dispatch }, donorData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_DONOR_LOADING', true);
    commit('SET_CREATE_DONOR', {});
    try {
      const res = await apiClient.post(`/donors`, donorData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_DONOR', res.data);
        commit('SET_CREATE_DONOR_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_DONOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateDonor: async ({ commit, dispatch }, donorData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_DONOR', {});
    commit('SET_UPDATE_DONOR_LOADING', true);
    try {
      const res = await apiClient.put(`/donors/${donorData._id}`, donorData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_DONOR', res.data);
        commit('SET_UPDATE_DONOR_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_DONOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingDonor: state =>
    state.donorsLoading ||
    state.donorLoading ||
    state.createDonorLoading ||
    state.updateDonorLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
