import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  districts: {},
  districtsLoading: false,

  districtsData: [],

  district: {},
  districtLoading: false,

  createDistrict: {},
  createDistrictLoading: false,

  updateDistrict: {},
  updateDistrictLoading: false
};

// mutations
const mutations = {
  SET_DISTRICTS: (state, districts) => {
    state.districts = districts;
  },
  SET_DISTRICTS_LOADING: (state, districtsLoading) => {
    state.districtsLoading = districtsLoading;
  },

  SET_DISTRICTS_DATA: (state, districtsData) => {
    state.districtsData = districtsData;
  },

  SET_DISTRICT: (state, district) => {
    state.district = district;
  },
  SET_DISTRICT_LOADING: (state, districtLoading) => {
    state.districtLoading = districtLoading;
  },

  SET_CREATE_DISTRICT: (state, createDistrict) => {
    if (createDistrict?.district) {
      state.districts = {
        ...state.districts,
        data: [createDistrict.district, ...state.districts.data]
      };
    }
    state.createDistrict = createDistrict;
  },
  SET_CREATE_DISTRICT_LOADING: (state, createDistrictLoading) => {
    state.createDistrictLoading = createDistrictLoading;
  },

  SET_UPDATE_DISTRICT: (state, updateDistrict) => {
    state.districts.data = state.districts.data.map(item => {
      if (item._id === updateDistrict?.district?._id) {
        item = updateDistrict.district;
      }
      return item;
    });
    state.updateDistrict = updateDistrict;
  },
  SET_UPDATE_DISTRICT_LOADING: (state, updateDistrictLoading) => {
    state.updateDistrictLoading = updateDistrictLoading;
  }
};

// actions
const actions = {
  onFetchDistricts: async ({ commit, dispatch }, filtersData) => {
    commit('SET_DISTRICTS_LOADING', true);
    // commit('SET_DISTRICTS', {});
    try {
      const res = await apiClient.get('/districts', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_DISTRICTS', res.data);
      commit('SET_DISTRICTS_LOADING', false);
    } catch (error) {
      commit('SET_DISTRICTS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchDistrict: async ({ commit, dispatch }, districtId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DISTRICT_LOADING', true);
    commit('SET_DISTRICT', {});
    try {
      const res = await apiClient.get(`/districts/${districtId}`);
      if (res.status >= 200) {
        commit('SET_DISTRICT', res.data.district);
        setTimeout(() => {
          commit('SET_DISTRICT_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_DISTRICT_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateDistrict: async ({ commit, dispatch }, districtData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_DISTRICT_LOADING', true);
    commit('SET_CREATE_DISTRICT', {});
    try {
      const res = await apiClient.post(`/districts`, districtData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_DISTRICT', res.data);
        commit('SET_CREATE_DISTRICT_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_DISTRICT_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateDistrict: async ({ commit, dispatch }, districtData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_DISTRICT', {});
    commit('SET_UPDATE_DISTRICT_LOADING', true);
    try {
      const res = await apiClient.put(
        `/districts/${districtData._id}`,
        districtData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_DISTRICT', res.data);
        commit('SET_UPDATE_DISTRICT_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_DISTRICT_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  districtsLabels: state => state.districts.data.map(item => item.name),
  districtsColors: state => state.districts.data.map(item => item.color),
  districtsData: state => state.districtsData,
  loadingDistrict: state =>
    state.districtsLoading ||
    state.districtLoading ||
    state.createDistrictLoading ||
    state.updateDistrictLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
