import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  user: {},
  userLoading: false,

  users: {},
  usersLoading: false,

  createUser: {},
  createUserLoading: false,

  updateUser: {},
  updateUserLoading: false,

  deleteUser: {},
  deleteUserLoading: false
};

// mutations
const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_USER_LOADING: (state, userLoading) => {
    state.userLoading = userLoading;
  },

  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_USERS_LOADING: (state, usersLoading) => {
    state.usersLoading = usersLoading;
  },

  SET_CREATE_USER: (state, createUser) => {
    if (createUser?.user) {
      state.users = {
        ...state.users,
        data: [
          { ...createUser.user, _id: createUser.user.id },
          ...state.users.data
        ]
      };
    }
    state.createUser = createUser;
  },
  SET_CREATE_USER_LOADING: (state, createUserLoading) => {
    state.createUserLoading = createUserLoading;
  },

  SET_UPDATE_USER: (state, updateUser) => {
    state.users.data = state.users.data.map(item => {
      if (item._id === updateUser?.user?._id) {
        item = updateUser.user;
      }
      return item;
    });
    state.updateUser = updateUser;
  },
  SET_UPDATE_USER_LOADING: (state, updateUserLoading) => {
    state.updateUserLoading = updateUserLoading;
  },

  SET_DELETE_USER: (state, deleteUser) => {
    state.users.data = state.users.data.filter(
      item => item._id !== deleteUser.id
    );
  },
  SET_DELETE_USER_LOADING: (state, deleteUserLoading) => {
    state.deleteUserLoading = deleteUserLoading;
  }
};

// actions
const actions = {
  onFetchUser: async ({ commit, dispatch }, userId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_USER_LOADING', true);
    commit('SET_USER', {});
    try {
      const res = await apiClient.get(`/users/${userId}`);
      if (res.status >= 200) {
        commit('SET_USER', res.data.user);
        commit('SET_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchUsers: async ({ commit, dispatch }, filtersData) => {
    commit('SET_USERS_LOADING', true);
    try {
      const res = await apiClient.get('/users', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_USERS', res.data);
      commit('SET_USERS_LOADING', false);
    } catch (error) {
      commit('SET_USERS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateUser: async ({ commit, dispatch }, userData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_USER_LOADING', true);
    commit('SET_CREATE_USER', {});
    try {
      const res = await apiClient.post(`/users`, userData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_USER', res.data);
        commit('SET_CREATE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateUser: async ({ commit, dispatch }, userData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_USER', {});
    commit('SET_UPDATE_USER_LOADING', true);
    try {
      const res = await apiClient.put(`/users/${userData._id}`, userData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_USER', res.data);
        commit('SET_UPDATE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteUser: async ({ commit, dispatch }, userId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(`/users/${userId}`);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingUser: state =>
    state.userLoading ||
    state.usersLoading ||
    state.createUserLoading ||
    state.updateUserLoading ||
    state.deleteUserLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
