import Vue from 'vue';
import VueHtml2Canvas from 'vue-html2canvas';
import VueFileAgent from 'vue-file-agent';
import { LMap, LTileLayer, LMarker, LControlLayers } from 'vue2-leaflet';

import App from './App.vue';
import router from './router';
import store from './vuex/store';
import vuetify from './plugins/vuetify';

import './plugins/meta';
import 'echarts';
import 'vue-file-agent/dist/vue-file-agent.css';
import ECharts from 'vue-echarts'; // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/gauge';

import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/line';

Vue.config.productionTip = false;

Vue.use(VueHtml2Canvas);

Vue.use(VueFileAgent);

// register component to use
Vue.component('v-chart', ECharts);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control-layers', LControlLayers);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
