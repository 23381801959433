import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../vuex/store';

Vue.use(VueRouter);

let userRole;

const checkLoggedIn = async () => {
  if (!store.getters['auth/loggedIn']) {
    await store.dispatch('auth/tryAutoLogin');
  }
  userRole = store.getters['auth/userRole'];
  return store.getters['auth/loggedIn'];
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  switch (to.name) {
    case 'login':
    case 'forgotPassword':
    case 'resetPassword':
      if (await checkLoggedIn()) {
        return next('/app');
      }
      return next();
    case 'users':
      if (await checkLoggedIn()) {
        if (userRole === 'admin') {
          return next();
        }
        return next('/app/home');
      }
      return next('/login');
    default:
      if (await checkLoggedIn()) {
        return next();
      }
      return next('/login');
  }
});

export default router;
