/* eslint-disable no-shadow */
// import axios from 'axios';
import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  center: {},
  centerLoading: false,

  centers: {},
  centersLoading: false,
  total: 0,
  totalRegistered: 0,
  totalRegisteredMale: 0,
  totalRegisteredFemale: 0,
  totalDistributed: 0,
  distributedFemale: 0,
  distributedMale: 0,
  totalPercentage: 0,
  femalePercentage: 0,
  malePercentage: 0,
  beneficiaries: 0,
  males: 0,
  females: 0,
  office: null,
  constituency: null,

  createCenter: {},
  createCenterLoading: false,

  updateCenter: {},
  updateCenterLoading: false,

  deleteCenter: {},
  deleteCenterLoading: false
};

// mutations
const mutations = {
  SET_CENTER: (state, center) => {
    state.center = center;
    state.office = center.office || null;
    state.constituency = center.constituency || null;
  },
  SET_CENTER_LOADING: (state, centerLoading) => {
    state.centerLoading = centerLoading;
  },

  SET_CENTERS: (state, centers) => {
    state.centers = centers;
    state.total = centers.total || 0;

    state.totalRegistered = centers.totalRegistered || 0;
    state.totalRegisteredMale = centers.totalRegisteredMale || 0;
    state.totalRegisteredFemale = centers.totalRegisteredFemale || 0;
    state.totalDistributed = centers.totalDistributed || 0;
    state.distributedMale = centers.distributedMale || 0;
    state.distributedFemale = centers.distributedFemale || 0;
    state.totalPercentage = centers.totalPercentage || 0;
    state.malePercentage = centers.malePercentage || 0;
    state.femalePercentage = centers.femalePercentage || 0;
    if (centers.data) {
      centers.data.forEach(center => {
        state.totalRegistered += center.totalRegistered;
        state.totalRegisteredMale += center.totalRegisteredMale;
        state.totalRegisteredFemale += center.totalRegisteredFemale;
        state.totalDistributed += center.totalDistributed;
        state.distributedMale += center.distributedMale;
        state.distributedFemale += center.distributedFemale;
        state.totalPercentage += center.totalPercentage;
        state.malePercentage += center.malePercentage;
        state.femalePercentage += center.femalePercentage;
      });
    }
  },
  SET_CENTERS_LOADING: (state, centersLoading) => {
    state.centersLoading = centersLoading;
  },

  SET_CREATE_CENTER: (state, createCenter) => {
    state.createCenter = createCenter;
  },
  SET_CREATE_CENTER_LOADING: (state, createCenterLoading) => {
    state.createCenterLoading = createCenterLoading;
  },

  SET_UPDATE_CENTER: (state, updateCenter) => {
    state.updateCenter = updateCenter;
  },
  SET_UPDATE_CENTER_LOADING: (state, updateCenterLoading) => {
    state.updateCenterLoading = updateCenterLoading;
  },

  SET_DELETE_CENTER: (state, deleteCenter) => {
    state.centers.data = state.centers.data.filter(
      item => item._id !== deleteCenter.id
    );
  },
  SET_DELETE_CENTER_LOADING: (state, deleteCenterLoading) => {
    state.deleteCenterLoading = deleteCenterLoading;
  }
};

// actions
const actions = {
  onFetchCenters: async ({ commit, dispatch }, filterOptions) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CENTERS_LOADING', true);
    try {
      const res = await apiClient.get('/centers', {
        params: { limit: 2000, ...filterOptions },
        paramsSerializer: qs.stringify
      });
      commit('SET_CENTERS', res.data);
      commit('SET_CENTERS_LOADING', false);
      if (res.data?.data.length === 0) {
        dispatch(
          'notify/show',
          { type: 'info', message: 'No centers result found.' },
          { root: true }
        );
      }
    } catch (error) {
      commit('SET_CENTERS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchCenter: async ({ commit, dispatch }, centerId) => {
    commit('SET_CENTER_LOADING', true);
    commit('SET_CENTER', {});
    try {
      const res = await apiClient.get(`/centers/${centerId}`);
      commit('SET_CENTER', res.data?.center);
      commit('SET_CENTER_LOADING', false);
    } catch (error) {
      commit('SET_CENTER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateCenter: async ({ commit, dispatch }, centerData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_CENTER_LOADING', true);
    commit('SET_CREATE_CENTER', {});
    try {
      const res = await apiClient.post(`/centers`, centerData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_CENTER', res.data);
        commit('SET_CREATE_CENTER_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_CENTER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateCenter: async ({ commit, dispatch }, centerData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_CENTER_LOADING', true);
    commit('SET_UPDATE_CENTER', {});
    try {
      const res = await apiClient.put(`/centers/${centerData._id}`, centerData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_CENTER', res.data);
        commit('SET_UPDATE_CENTER_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_CENTER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteCenter: async ({ commit, dispatch }, centerId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(`/centers/${centerId}`);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingCenter: state =>
    state.centerLoading ||
    state.centersLoading ||
    state.createCenterLoading ||
    state.updateCenterLoading ||
    state.deleteCenterLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
