import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    options: {
      minifyTheme,
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value)
      }
    },
    dark: JSON.parse(localStorage.getItem('darkMode')) || false,
    themes: {
      light: {
        primary: '#0464b5'
      },
      dark: {
        primary: '#0464b5'
      }
    }
  }
});
