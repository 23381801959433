/* eslint-disable no-shadow */
// import axios from 'axios';
import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  office: {},
  officeLoading: false,

  offices: {},
  officesLoading: false,

  createOffice: {},
  createOfficeLoading: false,

  updateOffice: {},
  updateOfficeLoading: false,

  deleteOffice: {},
  deleteOfficeLoading: false
};

// mutations
const mutations = {
  SET_OFFICE: (state, office) => {
    state.office = office;
  },
  SET_OFFICE_LOADING: (state, officeLoading) => {
    state.officeLoading = officeLoading;
  },

  SET_OFFICES: (state, offices) => {
    state.offices = offices;
  },
  SET_OFFICES_LOADING: (state, officesLoading) => {
    state.officesLoading = officesLoading;
  },

  SET_CREATE_OFFICE: (state, createOffice) => {
    state.createOffice = createOffice;
  },
  SET_CREATE_OFFICE_LOADING: (state, createOfficeLoading) => {
    state.createOfficeLoading = createOfficeLoading;
  },

  SET_UPDATE_OFFICE: (state, updateOffice) => {
    state.updateOffice = updateOffice;
  },
  SET_UPDATE_OFFICE_LOADING: (state, updateOfficeLoading) => {
    state.updateOfficeLoading = updateOfficeLoading;
  },

  SET_DELETE_OFFICE: (state, deleteOffice) => {
    state.offices.data = state.offices.data.filter(
      item => item._id !== deleteOffice.id
    );
  },
  SET_DELETE_OFFICE_LOADING: (state, deleteOfficeLoading) => {
    state.deleteOfficeLoading = deleteOfficeLoading;
  }
};

// actions
const actions = {
  onFetchOffices: async ({ commit, dispatch }, filterOptions) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_OFFICES_LOADING', true);
    try {
      const res = await apiClient.get('/offices', {
        params: { limit: 2000, ...filterOptions },
        paramsSerializer: qs.stringify
      });
      commit('SET_OFFICES', res.data);
      commit('SET_OFFICES_LOADING', false);
      if (res.data?.data.length === 0) {
        dispatch(
          'notify/show',
          { type: 'info', message: 'No offices result found.' },
          { root: true }
        );
      }
    } catch (error) {
      commit('SET_OFFICES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchOffice: async ({ commit, dispatch }, officeId) => {
    commit('SET_OFFICE_LOADING', true);
    commit('SET_OFFICE', {});
    try {
      const res = await apiClient.get(`/offices/${officeId}`);
      commit('SET_OFFICE', res.data?.office);
      commit('SET_OFFICE_LOADING', false);
    } catch (error) {
      commit('SET_OFFICE_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateOffice: async ({ commit, dispatch }, officeData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_OFFICE_LOADING', true);
    commit('SET_CREATE_OFFICE', {});
    try {
      const res = await apiClient.post(`/offices`, officeData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_OFFICE', res.data);
        commit('SET_CREATE_OFFICE_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_OFFICE_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateOffice: async ({ commit, dispatch }, officeData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_OFFICE_LOADING', true);
    commit('SET_UPDATE_OFFICE', {});
    try {
      const res = await apiClient.put(`/offices/${officeData._id}`, officeData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_OFFICE', res.data);
        commit('SET_UPDATE_OFFICE_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_OFFICE_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteOffice: async ({ commit, dispatch }, officeId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(`/offices/${officeId}`);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingOffice: state =>
    state.officeLoading ||
    state.officesLoading ||
    state.createOfficeLoading ||
    state.updateOfficeLoading ||
    state.deleteOfficeLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
