import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  sectors: {},
  sectorsLoading: false,

  sector: {},
  sectorLoading: false,

  createSector: {},
  createSectorLoading: false,

  updateSector: {},
  updateSectorLoading: false
};

// mutations
const mutations = {
  SET_SECTORS: (state, sectors) => {
    state.sectors = sectors;
  },
  SET_SECTORS_LOADING: (state, sectorsLoading) => {
    state.sectorsLoading = sectorsLoading;
  },

  SET_SECTOR: (state, sector) => {
    state.sector = sector;
  },
  SET_SECTOR_LOADING: (state, sectorLoading) => {
    state.sectorLoading = sectorLoading;
  },

  SET_CREATE_SECTOR: (state, createSector) => {
    if (createSector?.sector) {
      state.sectors = {
        ...state.sectors,
        data: [createSector.sector, ...state.sectors.data]
      };
    }
    state.createSector = createSector;
  },
  SET_CREATE_SECTOR_LOADING: (state, createSectorLoading) => {
    state.createSectorLoading = createSectorLoading;
  },

  SET_UPDATE_SECTOR: (state, updateSector) => {
    state.sectors.data = state.sectors.data.map(item => {
      if (item._id === updateSector?.sector?._id) {
        item = updateSector.sector;
      }
      return item;
    });
    state.updateSector = updateSector;
  },
  SET_UPDATE_SECTOR_LOADING: (state, updateSectorLoading) => {
    state.updateSectorLoading = updateSectorLoading;
  }
};

// actions
const actions = {
  onFetchSectors: async ({ commit, dispatch }, filtersData) => {
    commit('SET_SECTORS_LOADING', true);
    // commit('SET_SECTORS', {});
    try {
      const res = await apiClient.get('/sectors', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_SECTORS', res.data);
      commit('SET_SECTORS_LOADING', false);
    } catch (error) {
      commit('SET_SECTORS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchSector: async ({ commit, dispatch }, sectorId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_SECTOR_LOADING', true);
    commit('SET_SECTOR', {});
    try {
      const res = await apiClient.get(`/sectors/${sectorId}`);
      if (res.status >= 200) {
        commit('SET_SECTOR', res.data.sector);
        setTimeout(() => {
          commit('SET_SECTOR_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_SECTOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateSector: async ({ commit, dispatch }, sectorData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_SECTOR_LOADING', true);
    commit('SET_CREATE_SECTOR', {});
    try {
      const res = await apiClient.post(`/sectors`, sectorData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_SECTOR', res.data);
        commit('SET_CREATE_SECTOR_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_SECTOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateSector: async ({ commit, dispatch }, sectorData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_SECTOR', {});
    commit('SET_UPDATE_SECTOR_LOADING', true);
    try {
      const res = await apiClient.put(`/sectors/${sectorData._id}`, sectorData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_SECTOR', res.data);
        commit('SET_UPDATE_SECTOR_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_SECTOR_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingSector: state =>
    state.sectorsLoading ||
    state.sectorLoading ||
    state.createSectorLoading ||
    state.updateSectorLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
