import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  sdgs: {},
  sdgsLoading: false,

  sdg: {},
  sdgLoading: false,

  createSdg: {},
  createSdgLoading: false,

  updateSdg: {},
  updateSdgLoading: false
};

// mutations
const mutations = {
  SET_SDGS: (state, sdgs) => {
    state.sdgs = sdgs;
  },
  SET_SDGS_LOADING: (state, sdgsLoading) => {
    state.sdgsLoading = sdgsLoading;
  },

  SET_SDG: (state, sdg) => {
    state.sdg = sdg;
  },
  SET_SDG_LOADING: (state, sdgLoading) => {
    state.sdgLoading = sdgLoading;
  },

  SET_CREATE_SDG: (state, createSdg) => {
    if (createSdg?.sdg) {
      state.sdgs = { ...state.sdgs, data: [createSdg.sdg, ...state.sdgs.data] };
    }
    state.createSdg = createSdg;
  },
  SET_CREATE_SDG_LOADING: (state, createSdgLoading) => {
    state.createSdgLoading = createSdgLoading;
  },

  SET_UPDATE_SDG: (state, updateSdg) => {
    state.sdgs.data = state.sdgs.data.map(item => {
      if (item._id === updateSdg?.sdg?._id) {
        item = updateSdg.sdg;
      }
      return item;
    });
    state.updateSdg = updateSdg;
  },
  SET_UPDATE_SDG_LOADING: (state, updateSdgLoading) => {
    state.updateSdgLoading = updateSdgLoading;
  }
};

// actions
const actions = {
  onFetchSdgs: async ({ commit, dispatch }, filtersData) => {
    commit('SET_SDGS_LOADING', true);
    try {
      const res = await apiClient.get('/sdgs', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_SDGS', res.data);
      commit('SET_SDGS_LOADING', false);
    } catch (error) {
      commit('SET_SDGS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchSdg: async ({ commit, dispatch }, sdgId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_SDG_LOADING', true);
    commit('SET_SDG', {});
    try {
      const res = await apiClient.get(`/sdgs/${sdgId}`);
      if (res.status >= 200) {
        commit('SET_SDG', res.data.sdg);
        setTimeout(() => {
          commit('SET_SDG_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_SDG_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateSdg: async ({ commit, dispatch }, sdgData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_SDG_LOADING', true);
    commit('SET_CREATE_SDG', {});
    try {
      const res = await apiClient.post(`/sdgs`, sdgData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_SDG', res.data);
        commit('SET_CREATE_SDG_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_SDG_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateSdg: async ({ commit, dispatch }, sdgData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_SDG', {});
    commit('SET_UPDATE_SDG_LOADING', true);
    try {
      const res = await apiClient.put(`/sdgs/${sdgData._id}`, sdgData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_SDG', res.data);
        commit('SET_UPDATE_SDG_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_SDG_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingSdg: state =>
    state.sdgsLoading ||
    state.sdgLoading ||
    state.createSdgLoading ||
    state.updateSdgLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
