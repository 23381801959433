<template>
  <router-view />
</template>
<script>
require('regenerator-runtime');

export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: `Field Information System - %s`,
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    };
  },
  created() {
    // this.$store.dispatch('program/onFetchProgramsByUser');
  }
};
</script>
