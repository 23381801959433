/* eslint-disable no-shadow */
// import axios from 'axios';
import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  subSubConstituency: {},
  subSubConstituencyLoading: false,

  subConstituencies: {},
  subConstituenciesLoading: false,

  createSubConstituency: {},
  createSubConstituencyLoading: false,

  updateSubConstituency: {},
  updateSubConstituencyLoading: false,

  deleteSubConstituency: {},
  deleteSubConstituencyLoading: false
};

// mutations
const mutations = {
  SET_SUBCONSTITUENCY: (state, subSubConstituency) => {
    state.subSubConstituency = subSubConstituency;
  },
  SET_SUBCONSTITUENCY_LOADING: (state, subSubConstituencyLoading) => {
    state.subSubConstituencyLoading = subSubConstituencyLoading;
  },

  SET_SUBCONSTITUENCIES: (state, subConstituencies) => {
    state.subConstituencies = subConstituencies;
  },
  SET_SUBCONSTITUENCIES_LOADING: (state, subConstituenciesLoading) => {
    state.subConstituenciesLoading = subConstituenciesLoading;
  },

  SET_CREATE_SUBCONSTITUENCY: (state, createSubConstituency) => {
    state.createSubConstituency = createSubConstituency;
  },
  SET_CREATE_SUBCONSTITUENCY_LOADING: (state, createSubConstituencyLoading) => {
    state.createSubConstituencyLoading = createSubConstituencyLoading;
  },

  SET_UPDATE_SUBCONSTITUENCY: (state, updateSubConstituency) => {
    state.updateSubConstituency = updateSubConstituency;
  },
  SET_UPDATE_SUBCONSTITUENCY_LOADING: (state, updateSubConstituencyLoading) => {
    state.updateSubConstituencyLoading = updateSubConstituencyLoading;
  },

  SET_DELETE_SUBCONSTITUENCY: (state, deleteSubConstituency) => {
    state.subConstituencies.data = state.subConstituencies.data.filter(
      item => item._id !== deleteSubConstituency.id
    );
  },
  SET_DELETE_SUBCONSTITUENCY_LOADING: (state, deleteSubConstituencyLoading) => {
    state.deleteSubConstituencyLoading = deleteSubConstituencyLoading;
  }
};

// actions
const actions = {
  onFetchSubConstituencies: async ({ commit, dispatch }, filterOptions) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_SUBCONSTITUENCIES_LOADING', true);
    try {
      const res = await apiClient.get('/subconstituencies', {
        params: { limit: 2000, ...filterOptions },
        paramsSerializer: qs.stringify
      });
      commit('SET_SUBCONSTITUENCIES', res.data);
      commit('SET_SUBCONSTITUENCIES_LOADING', false);
      if (res.data?.data.length === 0) {
        dispatch(
          'notify/show',
          { type: 'info', message: 'No subConstituencies result found.' },
          { root: true }
        );
      }
    } catch (error) {
      commit('SET_SUBCONSTITUENCIES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchSubConstituency: async (
    { commit, dispatch },
    subSubConstituencyId
  ) => {
    commit('SET_SUBCONSTITUENCY_LOADING', true);
    commit('SET_SUBCONSTITUENCY', {});
    try {
      const res = await apiClient.get(
        `/subconstituencies/${subSubConstituencyId}`
      );
      commit('SET_SUBCONSTITUENCY', res.data?.subSubConstituency);
      commit('SET_SUBCONSTITUENCY_LOADING', false);
    } catch (error) {
      commit('SET_SUBCONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateSubConstituency: async (
    { commit, dispatch },
    subSubConstituencyData
  ) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_SUBCONSTITUENCY_LOADING', true);
    commit('SET_CREATE_SUBCONSTITUENCY', {});
    try {
      const res = await apiClient.post(
        `/subconstituencies`,
        subSubConstituencyData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_SUBCONSTITUENCY', res.data);
        commit('SET_CREATE_SUBCONSTITUENCY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_SUBCONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateSubConstituency: async (
    { commit, dispatch },
    subSubConstituencyData
  ) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_SUBCONSTITUENCY_LOADING', true);
    commit('SET_UPDATE_SUBCONSTITUENCY', {});
    try {
      const res = await apiClient.put(
        `/subconstituencies/${subSubConstituencyData._id}`,
        subSubConstituencyData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_SUBCONSTITUENCY', res.data);
        commit('SET_UPDATE_SUBCONSTITUENCY_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_SUBCONSTITUENCY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteSubConstituency: async (
    { commit, dispatch },
    subSubConstituencyId
  ) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(
        `/subconstituencies/${subSubConstituencyId}`
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingSubConstituency: state =>
    state.subSubConstituencyLoading ||
    state.subConstituenciesLoading ||
    state.createSubConstituencyLoading ||
    state.updateSubConstituencyLoading ||
    state.deleteSubConstituencyLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
