import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import pathify from '@/plugins/vuex-pathify';

const strict = process.env.NODE_ENV !== 'production';

const plugins = strict ? [createLogger({}), pathify.plugin] : [pathify.plugin];

Vue.use(Vuex);

const state = {
  drawer: null,
  breadcrumbsItems: [],
  kinds: [
    { label: 'Goods', value: 'Goods' },
    { label: 'Works', value: 'Works' }
  ],
  pwds: [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' }
  ],
  centerTypes: [
    { label: 'General', value: 'General' },
    { label: 'Displaced', value: 'Displaced' }
  ],
  regions: [
    { label: 'East', value: 'East' },
    { label: 'West', value: 'West' },
    { label: 'South', value: 'South' }
  ],
  headerLinks: [
    {
      icon: 'mdi-logout',
      text: 'logout',
      url: '/login'
    }
  ],
  sidebarLinks: [
    {
      icon: 'mdi-home',
      text: 'Home',
      url: '/app/home'
    },
    // {
    //   icon: 'mdi-chart-scatter-plot',
    //   text: 'Activities',
    //   url: '/app/activities'
    // },
    // {
    //   icon: 'mdi-cogs',
    //   text: 'Settings',
    //   url: '/app/settings'
    // },
    {
      icon: 'mdi-account-group',
      text: 'Users',
      url: '/app/users'
    }
    // {
    //   icon: 'mdi-view-dashboard',
    //   text: 'SFL',
    //   url: '/app/sfl'
    // },
    // {
    //   icon: 'mdi-view-dashboard',
    //   text: 'CSI',
    //   url: '/app/csi'
    // },
    // {
    //   icon: 'mdi-chevron-up',
    //   iconAlt: 'mdi-chevron-down',
    //   text: 'Settings',
    //   model: false,
    //   children: [
    //     { text: 'SDGs', url: '/app/sdgs' },
    //     { text: 'Donors', url: '/app/donors' },
    //     { text: 'Sectors', url: '/app/sectors' },
    //     { text: 'Districts', url: '/app/districts' },
    //     { text: 'Municipalities', url: '/app/municipalities' },
    //     { text: 'Project Statuses', url: '/app/project-statuses' }
    //   ]
    // }
  ]
};

const mutations = {
  SET_DRAWER(state, drawer) {
    state.drawer = drawer;
  },
  SET_BREADCRUMBS(state, breadcrumbsItems) {
    state.breadcrumbsItems = breadcrumbsItems;
  }
};

const actions = {};

const getters = {
  drawer: state => state.drawer
};

const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins,
  modules
});
