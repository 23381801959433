/* eslint-disable no-shadow */
// import axios from 'axios';
import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  activity: {},
  activityLoading: false,

  activities: {},
  activitiesLoading: false,
  completed: 0,
  beneficiaries: 0,
  males: 0,
  females: 0,

  createActivity: {},
  createActivityLoading: false,

  updateActivity: {},
  updateActivityLoading: false,

  deleteActivity: {},
  deleteActivityLoading: false
};

// mutations
const mutations = {
  SET_ACTIVITY: (state, activity) => {
    state.activity = activity;
  },
  SET_ACTIVITY_LOADING: (state, activityLoading) => {
    state.activityLoading = activityLoading;
  },

  SET_ACTIVITIES: (state, activities) => {
    state.activities = activities;
    state.completed = activities.total || 0;
    state.beneficiaries = 0;
    state.males = 0;
    state.females = 0;
    if (activities?.data) {
      activities.data.forEach(activity => {
        state.beneficiaries += activity.beneficiaries;
        state.males += activity.male;
        state.females += activity.female;
      });
    }
  },
  SET_ACTIVITIES_LOADING: (state, activitiesLoading) => {
    state.activitiesLoading = activitiesLoading;
  },

  SET_CREATE_ACTIVITY: (state, createActivity) => {
    state.createActivity = createActivity;
  },
  SET_CREATE_ACTIVITY_LOADING: (state, createActivityLoading) => {
    state.createActivityLoading = createActivityLoading;
  },

  SET_UPDATE_ACTIVITY: (state, updateActivity) => {
    state.updateActivity = updateActivity;
  },
  SET_UPDATE_ACTIVITY_LOADING: (state, updateActivityLoading) => {
    state.updateActivityLoading = updateActivityLoading;
  },

  SET_DELETE_ACTIVITY: (state, deleteActivity) => {
    state.activities.data = state.activities.data.filter(
      item => item._id !== deleteActivity.id
    );
  },
  SET_DELETE_ACTIVITY_LOADING: (state, deleteActivityLoading) => {
    state.deleteActivityLoading = deleteActivityLoading;
  }
};

// actions
const actions = {
  onFetchActivities: async ({ commit, dispatch }, filterOptions) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_ACTIVITIES_LOADING', true);
    try {
      const res = await apiClient.get('/activities', {
        params: { limit: 1000, ...filterOptions },
        paramsSerializer: qs.stringify
      });
      commit('SET_ACTIVITIES', res.data);
      commit('SET_ACTIVITIES_LOADING', false);
      if (res.data?.data.length === 0) {
        dispatch(
          'notify/show',
          { type: 'info', message: 'No activities result found.' },
          { root: true }
        );
      }
    } catch (error) {
      commit('SET_ACTIVITIES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchActivity: async ({ commit, dispatch }, activityId) => {
    commit('SET_ACTIVITY_LOADING', true);
    commit('SET_ACTIVITY', {});
    try {
      const res = await apiClient.get(`/activities/${activityId}`);
      commit('SET_ACTIVITY', res.data?.activity);
      setTimeout(() => {
        commit('SET_ACTIVITY_LOADING', false);
      }, 1000);
    } catch (error) {
      commit('SET_ACTIVITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateActivity: async ({ commit, dispatch }, activityData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_ACTIVITY_LOADING', true);
    commit('SET_CREATE_ACTIVITY', {});
    try {
      const res = await apiClient.post(`/activities`, activityData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_ACTIVITY', res.data);
        commit('SET_CREATE_ACTIVITY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_ACTIVITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateActivity: async ({ commit, dispatch }, activityData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_ACTIVITY_LOADING', true);
    commit('SET_UPDATE_ACTIVITY', {});
    try {
      const res = await apiClient.put(
        `/activities/${activityData._id}`,
        activityData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_ACTIVITY', res.data);
        commit('SET_UPDATE_ACTIVITY_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_ACTIVITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onDeleteActivity: async ({ commit, dispatch }, activityId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_DELETE_USER', {});
    commit('SET_DELETE_USER_LOADING', true);
    try {
      const res = await apiClient.delete(`/activities/${activityId}`);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_DELETE_USER', res.data);
        commit('SET_DELETE_USER_LOADING', false);
      }
    } catch (error) {
      commit('SET_DELETE_USER_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingActivity: state =>
    state.activityLoading ||
    state.activitiesLoading ||
    state.createActivityLoading ||
    state.updateActivityLoading ||
    state.deleteActivityLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
