const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "authLayout" */ '@/layout/AuthLayout'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "forgotPassword" */ '@/views/auth/ForgotPassword.vue'
          )
      },
      {
        path: '/reset-password/:userId',
        name: 'resetPassword',
        component: () =>
          import(
            /* webpackChunkName: "resetPassword" */ '@/views/auth/ResetPassword.vue'
          )
      }
    ]
  },
  {
    path: '/app',
    name: 'app',
    component: () =>
      import(/* webpackChunkName: "appLayout" */ '@/layout/AppLayout'),
    redirect: '/app/home',
    children: [
      {
        path: 'offices',
        name: 'offices',
        component: () =>
          import(/* webpackChunkName: "offices" */ '@/views/Office.vue'),
        props: true
      },
      {
        path: 'home/:programId?',
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        props: true
      },
      {
        path: 'home/show/:activityId?',
        name: 'home.show',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        props: true
      },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/Settings.vue'
          ),
        children: [
          {
            path: 'sdg/new',
            name: 'sdg.new',
            component: () =>
              import(
                /* webpackChunkName: "sdgEditor" */ '@/views/settings/sdg/SdgEditor.vue'
              )
          },
          {
            path: 'sdg/edit/:sdgId',
            name: 'sdg.edit',
            component: () =>
              import(
                /* webpackChunkName: "sdgEditor" */ '@/views/settings/sdg/SdgEditor.vue'
              ),
            props: true
          },

          {
            path: 'district/new',
            name: 'district.new',
            component: () =>
              import(
                /* webpackChunkName: "districtEditor" */ '@/views/settings/district/DistrictEditor.vue'
              )
          },
          {
            path: 'district/edit/:districtId',
            name: 'district.edit',
            component: () =>
              import(
                /* webpackChunkName: "districtEditor" */ '@/views/settings/district/DistrictEditor.vue'
              ),
            props: true
          },

          {
            path: 'donor/new',
            name: 'donor.new',
            component: () =>
              import(
                /* webpackChunkName: "donorEditor" */ '@/views/settings/donor/DonorEditor.vue'
              )
          },
          {
            path: 'donor/edit/:donorId',
            name: 'donor.edit',
            component: () =>
              import(
                /* webpackChunkName: "donorEditor" */ '@/views/settings/donor/DonorEditor.vue'
              ),
            props: true
          },

          {
            path: 'sector/new',
            name: 'sector.new',
            component: () =>
              import(
                /* webpackChunkName: "sectorEditor" */ '@/views/settings/sector/SectorEditor.vue'
              )
          },
          {
            path: 'sector/edit/:sectorId',
            name: 'sector.edit',
            component: () =>
              import(
                /* webpackChunkName: "sectorEditor" */ '@/views/settings/sector/SectorEditor.vue'
              ),
            props: true
          },

          {
            path: 'municipality/new',
            name: 'municipality.new',
            component: () =>
              import(
                /* webpackChunkName: "municipalityEditor" */ '@/views/settings/municipality/MunicipalityEditor.vue'
              )
          },
          {
            path: 'municipality/edit/:municipalityId',
            name: 'municipality.edit',
            component: () =>
              import(
                /* webpackChunkName: "municipalityEditor" */ '@/views/settings/municipality/MunicipalityEditor.vue'
              ),
            props: true
          },

          {
            path: 'project-status/new',
            name: 'projectStatus.new',
            component: () =>
              import(
                /* webpackChunkName: "projectStatusEditor" */ '@/views/settings/projectStatus/ProjectStatusEditor.vue'
              )
          },
          {
            path: 'project-status/edit/:projectStatusId',
            name: 'projectStatus.edit',
            component: () =>
              import(
                /* webpackChunkName: "projectStatusEditor" */ '@/views/settings/projectStatus/ProjectStatusEditor.vue'
              ),
            props: true
          },

          {
            path: 'program/new',
            name: 'program.new',
            component: () =>
              import(
                /* webpackChunkName: "programEditor" */ '@/views/settings/program/ProgramEditor.vue'
              )
          },
          {
            path: 'program/edit/:programId',
            name: 'program.edit',
            component: () =>
              import(
                /* webpackChunkName: "programEditor" */ '@/views/settings/program/ProgramEditor.vue'
              ),
            props: true
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        component: () =>
          import(
            /* webpackChunkName: "userList" */ '@/views/user/UserList.vue'
          ),
        children: [
          {
            path: 'new',
            name: 'user.new',
            component: () =>
              import(
                /* webpackChunkName: "userEditor" */ '@/views/user/UserEditor.vue'
              )
          },
          {
            path: 'update/:userId',
            name: 'user.update',
            component: () =>
              import(
                /* webpackChunkName: "userEditor" */ '@/views/user/UserEditor.vue'
              ),
            props: true
          }
        ]
      },
      {
        path: 'activities',
        name: 'activities',
        component: () =>
          import(
            /* webpackChunkName: "activitiesList" */ '@/views/activity/ActivitiesList.vue'
          )
      },
      {
        path: 'activities/new',
        name: 'activity.new',
        component: () =>
          import(
            /* webpackChunkName: "activityEditor" */ '@/views/activity/ActivityEditor.vue'
          )
      },
      {
        path: 'activities/update/:activityId',
        name: 'activity.update',
        component: () =>
          import(
            /* webpackChunkName: "activityEditor" */ '@/views/activity/ActivityEditor.vue'
          ),
        props: true
      },
      {
        path: 'centers/update/:centerId',
        name: 'center.update',
        component: () =>
          import(
            /* webpackChunkName: "activityEditor" */ '@/views/activity/ActivityEditor.vue'
          ),
        props: true
      }
    ]
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "authLayout" */ '@/layout/AuthLayout'),
    children: [
      {
        name: '404 Error',
        path: '',
        component: () =>
          import(/* webpackChunkName: "404-error" */ '@/views/Error')
      }
    ]
  }
];

export default routes;
