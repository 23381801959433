import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import apiClient from './axiosInstance';

const renderNotify = error => {
  const notify = {
    message: get(
      error,
      'response.data.message',
      'Something went wrong, please try again later'
    )
  };
  return notify;
};

export { get, apiClient, upperFirst, camelCase, renderNotify };
