import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  projectStatuses: {},
  projectStatusesLoading: false,

  projectStatus: {},
  projectStatusLoading: false,

  createProjectStatus: {},
  createProjectStatusLoading: false,

  updateProjectStatus: {},
  updateProjectStatusLoading: false
};

// mutations
const mutations = {
  SET_PROJECT_STATUSES: (state, projectStatuses) => {
    state.projectStatuses = projectStatuses;
  },
  SET_PROJECT_STATUSES_LOADING: (state, projectStatusesLoading) => {
    state.projectStatusesLoading = projectStatusesLoading;
  },

  SET_PROJECT_STATUS: (state, projectStatus) => {
    state.projectStatus = projectStatus;
  },
  SET_PROJECT_STATUS_LOADING: (state, projectStatusLoading) => {
    state.projectStatusLoading = projectStatusLoading;
  },

  SET_CREATE_PROJECT_STATUS: (state, createProjectStatus) => {
    if (createProjectStatus?.status) {
      state.projectStatuses = {
        ...state.projectStatuses,
        data: [createProjectStatus.status, ...state.projectStatuses.data]
      };
    }
    state.createProjectStatus = createProjectStatus;
  },
  SET_CREATE_PROJECT_STATUS_LOADING: (state, createProjectStatusLoading) => {
    state.createProjectStatusLoading = createProjectStatusLoading;
  },

  SET_UPDATE_PROJECT_STATUS: (state, updateProjectStatus) => {
    state.projectStatuses.data = state.projectStatuses.data.map(item => {
      if (item._id === updateProjectStatus?.status?._id) {
        item = updateProjectStatus.status;
      }
      return item;
    });
    state.updateProjectStatus = updateProjectStatus;
  },
  SET_UPDATE_PROJECT_STATUS_LOADING: (state, updateProjectStatusLoading) => {
    state.updateProjectStatusLoading = updateProjectStatusLoading;
  }
};

// actions
const actions = {
  onFetchProjectStatuses: async ({ commit, dispatch }, filtersData) => {
    commit('SET_PROJECT_STATUSES_LOADING', true);
    // commit('SET_PROJECT_STATUSES', {});
    try {
      const res = await apiClient.get('/statuses', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      commit('SET_PROJECT_STATUSES', res.data);
      commit('SET_PROJECT_STATUSES_LOADING', false);
    } catch (error) {
      commit('SET_PROJECT_STATUSES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchProjectStatus: async ({ commit, dispatch }, projectStatusId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_PROJECT_STATUS_LOADING', true);
    commit('SET_PROJECT_STATUS', {});
    try {
      const res = await apiClient.get(`/statuses/${projectStatusId}`);
      if (res.status >= 200) {
        commit('SET_PROJECT_STATUS', res.data.status);
        setTimeout(() => {
          commit('SET_PROJECT_STATUS_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_PROJECT_STATUS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateProjectStatus: async ({ commit, dispatch }, projectStatusData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_PROJECT_STATUS_LOADING', true);
    commit('SET_CREATE_PROJECT_STATUS', {});
    try {
      const res = await apiClient.post(`/statuses`, projectStatusData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_PROJECT_STATUS', res.data);
        commit('SET_CREATE_PROJECT_STATUS_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_PROJECT_STATUS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateProjectStatus: async ({ commit, dispatch }, projectStatusData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_PROJECT_STATUS', {});
    commit('SET_UPDATE_PROJECT_STATUS_LOADING', true);
    try {
      const res = await apiClient.put(
        `/statuses/${projectStatusData._id}`,
        projectStatusData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_PROJECT_STATUS', res.data);
        commit('SET_UPDATE_PROJECT_STATUS_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_PROJECT_STATUS_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingProjectStatus: state =>
    state.projectStatusesLoading ||
    state.projectStatusLoading ||
    state.createProjectStatusLoading ||
    state.updateProjectStatusLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
