import qs from 'qs';
import { get, apiClient, renderNotify } from '../../utils';

// initial state
const state = {
  municipalities: {},
  municipalitiesLoading: false,

  municipality: {},
  municipalityLoading: false,

  createMunicipality: {},
  createMunicipalityLoading: false,

  updateMunicipality: {},
  updateMunicipalityLoading: false
};

// mutations
const mutations = {
  SET_MUNICIPALITIES: (state, municipalities) => {
    state.municipalities = municipalities;
  },
  SET_MUNICIPALITIES_LOADING: (state, municipalitiesLoading) => {
    state.municipalitiesLoading = municipalitiesLoading;
  },

  SET_MUNICIPALITY: (state, municipality) => {
    state.municipality = municipality;
  },
  SET_MUNICIPALITY_LOADING: (state, municipalityLoading) => {
    state.municipalityLoading = municipalityLoading;
  },

  SET_CREATE_MUNICIPALITY: (state, createMunicipality) => {
    if (createMunicipality?.municipality) {
      state.municipalities = {
        ...state.municipalities,
        data: [createMunicipality.municipality, ...state.municipalities.data]
      };
    }
    state.createMunicipality = createMunicipality;
  },
  SET_CREATE_MUNICIPALITY_LOADING: (state, createMunicipalityLoading) => {
    state.createMunicipalityLoading = createMunicipalityLoading;
  },

  SET_UPDATE_MUNICIPALITY: (state, updateMunicipality) => {
    state.municipalities.data = state.municipalities.data.map(item => {
      if (item._id === updateMunicipality?.municipality?._id) {
        item = updateMunicipality.municipality;
      }
      return item;
    });
    state.updateMunicipality = updateMunicipality;
  },
  SET_UPDATE_MUNICIPALITY_LOADING: (state, updateMunicipalityLoading) => {
    state.updateMunicipalityLoading = updateMunicipalityLoading;
  }
};

// actions
const actions = {
  onFetchMunicipalities: async ({ commit, dispatch }, filtersData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_MUNICIPALITIES_LOADING', true);
    // commit('SET_MUNICIPALITIES', {});
    try {
      const res = await apiClient.get('/municipalities', {
        params: filtersData,
        paramsSerializer: qs.stringify
      });
      if (res.status >= 200) {
        commit('SET_MUNICIPALITIES', res.data);
        commit('SET_MUNICIPALITIES_LOADING', false);
      }
    } catch (error) {
      commit('SET_MUNICIPALITIES_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onFetchMunicipality: async ({ commit, dispatch }, municipalityId) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_MUNICIPALITY_LOADING', true);
    commit('SET_MUNICIPALITY', {});
    try {
      const res = await apiClient.get(`/municipalities/${municipalityId}`);
      if (res.status >= 200) {
        commit('SET_MUNICIPALITY', res.data.municipality);
        setTimeout(() => {
          commit('SET_MUNICIPALITY_LOADING', false);
        }, 1000);
      }
    } catch (error) {
      commit('SET_MUNICIPALITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onCreateMunicipality: async ({ commit, dispatch }, municipalityData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_CREATE_MUNICIPALITY_LOADING', true);
    commit('SET_CREATE_MUNICIPALITY', {});
    try {
      const res = await apiClient.post(`/municipalities`, municipalityData);
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_CREATE_MUNICIPALITY', res.data);
        commit('SET_CREATE_MUNICIPALITY_LOADING', false);
      }
    } catch (error) {
      commit('SET_CREATE_MUNICIPALITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  },

  onUpdateMunicipality: async ({ commit, dispatch }, municipalityData) => {
    dispatch('notify/hide', null, { root: true });
    commit('SET_UPDATE_MUNICIPALITY', {});
    commit('SET_UPDATE_MUNICIPALITY_LOADING', true);
    try {
      const res = await apiClient.put(
        `/municipalities/${municipalityData._id}`,
        municipalityData
      );
      if (res.status >= 200) {
        dispatch(
          'notify/show',
          {
            type: 'success',
            message: get(res, `data.info.message`)
          },
          { root: true }
        );
        commit('SET_UPDATE_MUNICIPALITY', res.data);
        commit('SET_UPDATE_MUNICIPALITY_LOADING', false);
      }
    } catch (error) {
      commit('SET_UPDATE_MUNICIPALITY_LOADING', false);
      if (error.response !== undefined) {
        if (error.response.status !== 401) {
          dispatch('notify/show', renderNotify(error), { root: true });
        }
      }
    }
  }
};

// getters
const getters = {
  loadingMunicipality: state =>
    state.municipalitiesLoading ||
    state.municipalityLoading ||
    state.createMunicipalityLoading ||
    state.updateMunicipalityLoading
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
