import { get, apiClient } from '../../utils';
// import router from '../../router';

// initial state
const state = {
  loginLoading: false,

  currentUserData: null,
  currentUserDataLoading: false,

  forgotPassword: null,
  forgotPasswordLoading: false,

  resetPasswordLoading: false
};

// mutations
const mutations = {
  SET_LOGIN_LOADING: (state, loginLoading) => {
    state.loginLoading = loginLoading;
  },

  SET_CURRENT_USER_DATA: (state, currentUserData) => {
    state.currentUserData = currentUserData;
  },
  SET_CURRENT_USER_DATA_LOADING: (state, currentUserDataLoading) => {
    state.currentUserDataLoading = currentUserDataLoading;
  },

  SET_FORGOT_PASSWORD: (state, forgotPassword) => {
    state.forgotPassword = forgotPassword;
  },
  SET_FORGOT_PASSWORD_LOADING: (state, forgotPasswordLoading) => {
    state.forgotPasswordLoading = forgotPasswordLoading;
  },

  SET_RESET_PASSWORD_LOADING: (state, resetPasswordLoading) => {
    state.resetPasswordLoading = resetPasswordLoading;
  }
};

// actions
const actions = {
  tryAutoLogin: async ({ commit, dispatch }) => {
    try {
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      commit('activity/SET_ACTIVITIES', {}, { root: true });
      const res = await apiClient.get('/auth/me');
      if (res.status === 200 && res.data?.user) {
        await dispatch('program/onFetchProgramsByUser', null, { root: true });
        commit('SET_CURRENT_USER_DATA', res.data.user);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
      }
    } catch (error) {
      commit('SET_CURRENT_USER_DATA', null);
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(
            error,
            'response.data.message',
            'Something went wrong, please try again later'
          )
        };
        if (error.response.status !== 401) {
          dispatch('notify/show', notify, { root: true });
        }
      }
    }
  },

  onLogin: async ({ commit, dispatch }, authData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_LOGIN_LOADING', true);
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      commit('activity/SET_ACTIVITIES', {}, { root: true });
      const res = await apiClient.post('/auth/login', {
        email: authData.email,
        password: authData.password
      });
      if (res.status === 200 && res.data?.token) {
        await dispatch('program/onFetchProgramsByUser', null, { root: true });
        commit('SET_CURRENT_USER_DATA', res.data.user);
        commit('SET_CURRENT_USER_DATA_LOADING', false);
        commit('SET_LOGIN_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOGIN_LOADING', false);
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(
            error,
            'response.data.message',
            'Something went wrong, please try again later'
          )
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  },

  onLogout: async ({ commit, dispatch }) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_CURRENT_USER_DATA_LOADING', true);
      const res = await apiClient.get('/auth/logout');
      if (res.status === 200) {
        const notify = {
          type: 'success',
          message: get(
            res,
            'data.message',
            'Something went wrong, please try again later'
          )
        };
        setTimeout(() => {
          dispatch('notify/show', notify, { root: true });
          commit('SET_CURRENT_USER_DATA_LOADING', false);
          commit('SET_CURRENT_USER_DATA', null);
        }, 800);
      }
    } catch (error) {
      commit('SET_CURRENT_USER_DATA_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(
            error,
            'response.data.message',
            'Something went wrong, please try again later'
          )
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  },

  onForgotPassword: async ({ commit, dispatch }, authData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_FORGOT_PASSWORD_LOADING', true);
      const res = await apiClient.post('/auth/forgot', {
        email: authData.email
      });
      if (res.status === 200 && res.data?.user?.id) {
        const notify = {
          type: 'success',
          message: res.data?.info?.message
        };
        dispatch('notify/show', notify, { root: true });
        commit('SET_FORGOT_PASSWORD', res.data.user.id);
        commit('SET_FORGOT_PASSWORD_LOADING', false);
      }
    } catch (error) {
      commit('SET_FORGOT_PASSWORD_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(
            error,
            'response.data.message',
            'Something went wrong, please try again later'
          )
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  },

  onResetPassword: async ({ commit, dispatch }, authData) => {
    try {
      dispatch('notify/hide', null, { root: true });
      commit('SET_RESET_PASSWORD_LOADING', true);
      const res = await apiClient.post('/auth/reset', {
        pin: authData.pin,
        userId: authData.userId,
        password: authData.password
      });
      if (res.status === 200 && res.data?.token) {
        commit('SET_RESET_PASSWORD_LOADING', false);
        commit('SET_CURRENT_USER_DATA', res.data.user);
      }
    } catch (error) {
      commit('SET_RESET_PASSWORD_LOADING', false);
      if (error.response !== undefined) {
        const notify = {
          message: get(
            error,
            'response.data.message',
            'Something went wrong, please try again later'
          )
        };
        dispatch('notify/show', notify, { root: true });
      }
    }
  }
};

// getters
const getters = {
  loginLoading: state => state.loginLoading,
  loggedIn: state => !!state.currentUserData,
  currentUserData: state => state.currentUserData,
  currentUserDataLoading: state => state.currentUserDataLoading,
  userRole: state => (state.currentUserData?.isAdmin ? 'admin' : 'user')
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
